.help_support_drawer {
  width: 800px;
  padding: 46px 20px 20px;
  height: 100%;
}
.chat_area {
  height: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  .custom_close_btn {
    position: absolute;
    top: 7px;
    left: 8px;
    cursor: pointer;
    width: 25px;
    height: 22px;
  }
  .chat_area_header {
    padding: 15px;
    border-bottom: 1px solid lightgray;
    h4 {
      font-weight: 600;
      font-family: "Inter", sans-serif !important;
      color: black;
      font-size: 14px;
      text-align: initial;
      line-height: inherit;
      letter-spacing: inherit;
      text-transform: capitalize;
      span {
        border: 1px solid lightgray;
        padding: 5px;
        border-radius: 3px;
        font-weight: 600;
        margin-right: 10px;
        font-family: "Inter", sans-serif !important;
      }
    }
  }
  .chat_area_wrap {
    height: calc(100% - 67px);
    .chat_msg_list_wrap {
      height: 100%;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      min-height: 1px;
      .chat_msg_container {
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        max-width: 1330px;
        margin: 0 auto;
        width: 100%;
        padding: 0 20px;
        position: relative;
        @include comman_scrollbar_firefox(#bfc6cf, transparent);
        .chat_msg_list {
          padding: 20px 15px 0;
          position: absolute;
          left: 0;
          right: 0;
          // overflow: auto;
          height: 100%;
          .message_section {
            display: flex;
            margin: 15px 0;
            &.message_left {
              justify-content: flex-start;
            }
            &.message_right {
              justify-content: flex-end;
              .msg_avtar {
                margin-left: 1.5rem;
              }
            }
            .msg_avtar {
              margin-right: 1.5rem;
              .avtar_img {
                width: 45px;
                height: 45px;
                border: 1px solid #ffffff;
                border-radius: 100%;
                filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.25));
                -webkit-filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.25));
                border-radius: 100%;
                img {
                  max-width: 100%;
                  width: 100%;
                  display: block;
                  max-height: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 100%;
                }
              }
            }
            .messages {
              background: #f5f7fb;
              padding: 10px;
              border-radius: 0 5px 5px 5px;
              min-width: 60px;
              max-width: 75%;
              .user_message {
                font-size: 13px;
                font-family: raleway_bold;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                p {
                  line-height: 1.5;
                  font-family: "Inter", sans-serif !important;
                  span.red {
                    color: #c00c2d;
                    font-family: "Inter", sans-serif !important;
                  }
                  &.user_title {
                    padding-right: 20px;
                    font-weight: 600;
                  }
                }
              }
              .message_content {
                padding: 10px 0 0;
                font-family: "Inter", sans-serif !important;
                font-size: 13px;
              }
            }
          }
        }
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: #bfc6cf;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
      }
      .chat_input_wrap {
        max-width: 1390px;
        width: 100%;
        padding: 10px 15px;
        margin: auto;
        position: relative;
        background: #fff;
        .chat_input_block {
          border: 1px solid #dddfe8;
          background: #fff;
          position: relative;
          bottom: 0;
          width: 100%;
          min-height: 50px;
          display: flex;
          align-items: flex-end;
          border-radius: 3px;
          .chat_editor {
            flex: 1 1;
            .msg_text {
              input {
                color: #333;
                height: 50px;
                width: 100%;
                padding: 15px;
                border: 0;
                font-weight: 600;
                outline: none;
                font-family: "Inter", sans-serif !important;
                &::placeholder {
                  color: #73788b;
                }
                &:focus {
                  outline: none !important;
                }
              }
            }
          }
        }
        .message_action {
          min-height: 50px;
          display: flex;
          align-items: center;

          .send-message {
            //border-radius: 30px;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            text-transform: uppercase;
            margin: 0 15px 0 0;
            cursor: pointer;
            background: rgb(51, 125, 204);
            border-radius: 3px;
            border: none;
            width: 34px;
            height: 34px;
            min-width: auto;
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Inter", sans-serif !important;
            svg {
              path {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
  .chat_msg_list::-webkit-scrollbar {
    display: none;
  }
}

.documentation_main_section {
  //height: calc(100vh - 86px);
  //background: white;
  //padding: 30px;
  .documentation_section {
    &_title {
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      margin-bottom: 20px;
      h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
      }
    }
    &_content {
      display: flex;
      .content_box {
        width: 274px;
        border: 1px solid rgb(224, 224, 224);
        border-radius: 5px;
        padding: 20px 23px;
        margin-right: 30px;
        &_icon {
          svg {
            width: 40px;
            height: 40px;
            color: #1976d2;
            margin-bottom: 10px;
          }
        }
        &_description {
          h3 {
            font-size: 16px;
            font-weight: 500;
          }
          p {
            line-height: 20px;
            font-size: 13px;
            margin-top: 5px;
          }
        }
      }
    }
    .documentation_how_to_start {
      //margin-top: 30px;
      .how_to_start_title {
        margin-bottom: 20px;
        flex-wrap: wrap;
        h2 {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 5px;
          font-family: "Inter", sans-serif !important;
        }
      }
      .how_to_start_content {
        display: flex;
        .start_content_box {
          width: 274px;
          border: 1px solid rgb(224, 224, 224);
          border-radius: 5px;
          margin-right: 30px;
          display: flex;
          justify-content: space-between;
          &_icon {
            background: #eeee;
            height: 58px;
            padding: 0 18px;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          &_description {
            h3 {
              height: 58px;
              display: flex;
              align-items: center;
              padding: 0 15px;
              font-weight: 500;
            }
          }
        }
      }
    }
    .document_record_status {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
    .document_record_status_resolved {
      display: flex;
      align-items: center;
      color: #75b537 !important;
      svg {
        margin-right: 5px;
      }
    }
  }
}

.video-tickets-section {
  .header_section_slider {
    height: 100px;
    text-align: center;
    padding: 34px;
  }
}

.video_ticket_images {
  .header_section_slider {
    padding: 0;
    height: 280px;
    .user_profile_pic img {
      height: 100%;
      vertical-align: middle;
    }
  }
  .ads_internal .close-icon {
    top: -4px;
  }
}

.ticket_video_image_section {
  text-align: center;
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: initial;
    line-height: 28px;
  }
  .loader_css {
    height: 43px;
  }
  .loader_ticket_video {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 268px;
  }
  .ticket_show.loader_show {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ticket_show {
    width: 436px;
    height: 291px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.customer_care_detail {
  .date-picker-details-section {
    margin-top: 10px;
    .date-label {
      font-weight: 500;
    }
    .care-datePicker {
      display: flex;
      margin-top: 5px;
      label {
        font-size: 13px;
      }
      .start-time-date {
        margin-right: 20px;
      }
      .end-time-date {
        margin-left: 20px;
      }
    }
    .start-time-date,
    .end-time-date {
      input {
        min-height: auto;
        //background: rgba(51, 51, 51, 0.5);
        border: 1px solid #cfd8e3;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        padding: 10px 24px 10px 10px;
        width: 100%;
        height: 44px;
      }

      .react-datepicker-time__header {
        font-family: "Inter", sans-serif;
      }
      li.react-datepicker__time-list-item {
        font-family: "Inter", sans-serif;
      }
      .react-datepicker-popper[data-placement^="bottom"] {
        padding-top: 0 !important;
      }
      .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box,
      .react-datepicker__time-container {
        width: 123px !important;
      }
      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle::before,
      .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::before,
      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle::after,
      .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::after {
        left: -14px !important;
      }
    }
  }
}

.faq-section {
  textarea {
    margin-top: 5px;
    width: 100%;
    border: 1px solid #cfd8e3;
    border-radius: 5px;
    padding: 16px;
    font-family: "Inter", sans-serif !important;
    &:focus {
      outline: 1px solid #0277bd !important;
    }
  }
}

// //////////////////////////////////// Responsive Page ////////////////////
@media screen and (min-width: 320px) and (max-width: 425px) {
  // Mobile View
  .css-17z2nlw,
  .css-wbpr84,
  .css-19y4als {
    width: 300px !important;
  }
  .help_support_drawer {
    width: 300px !important;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  // Tablet View
  .help_support_drawer {
    width: 500px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  // Laptop View
}

.Answer{
  margin-top: 7px !important;
}