@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

.container {
  max-width: 1249px;
  margin: auto;
}
body {
  background-color: #000;
}
.d-flex {
  display: flex;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #fff;
}
.txt-white {
  color: #fff;
}

.landing-main {
  h1 {
    font-family: "Josefin Sans", sans-serif !important;
  }
  h2 {
    font-family: "Josefin Sans", sans-serif !important;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 40px;
    }
  }
  h4 {
    font-family: "Josefin Sans", sans-serif !important;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  h5 {
    @media (max-width: 768px) {
      font-family: "Josefin Sans", sans-serif !important;
      font-size: 18px !important;
    }
  }
  .stickyhead {
    position: fixed;
    width: 100%;
    z-index: 5000;
  }
  .header-main {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::after {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 0.3s ease;
      width: 100%;
      z-index: -1;
    }
    .header-main {
      position: relative;
    }
    .online-player {
      position: relative;
    }
    .site-logo {
      align-items: center;
      gap: 24px;
      color: hsla(0, 0%, 100%, 0.5);
      @media (max-width: 768px) {
        gap: 8px;
      }
      img {
        @media (max-width: 1049px) {
          width: 60px !important;
        }
      }
      p {
        font-size: 14px;
        padding-left: 20px;
      }
    }
    .nav-manu {
      display: flex;
      align-items: center;
      gap: 24px;
      @media (max-width: 1049px) {
        gap: 20px;
        font-size: 14px;
      }
      li {
        a {
          font-size: 16px;

          &:hover {
            color: #fbbc05;
          }

          @media (max-width: 1049px) {
            font-size: 14px;
          }
        }
      }

      @media (max-width: 992px) {
        display: none !important;
      }
    }
    .toggal-btn {
      position: relative;
      display: none;
      @media (max-width: 992px) {
        display: block;
      }
      .togal-icon {
        width: 40px;
        height: 40px;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        svg {
          color: #fff;
          border-radius: 50px;
        }
      }
      .moblie-menu {
        .m-main-menu {
          position: absolute;
          top: 50px;
          z-index: 50000;
          right: 25px;
          width: 200px;
          background-color: #1c2431;
          padding: 10px 16px;
          border-radius: 10px;
          display: none;
          @media (max-width: 992px) {
            display: block;
          }
          li {
            padding: 14px 0;
            border-bottom: 1px solid #363636;
          }
        }
      }
    }
  }
  .banner-main {
    padding: 0px 16px;
    .animation-card {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: -1;
        left: 0;
        opacity: 0.4;
        top: 0;
      }
    }
    .banner-txt {
      gap: 30px;
      padding-top: 100px;
      display: flex;
      align-items: start;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 824px) {
        flex-direction: column;
        padding-top: 50px;
        gap: 50px;
      }
      h1 {
        color: #fff;
        padding-top: 30px;
        font-size: 48px;
        line-height: 75px;
        @media (max-width: 1099px) {
          font-size: 35px;
          line-height: 55px;
        }
        @media (max-width: 576px) {
          font-size: 26px;
          line-height: 40px;
        }
      }
      .screen-img {
        img {
          border-radius: 20px;
          height: 460px;
          object-fit: contain;
          @media (max-width: 1099px) {
            height: 360px;
          }
          @media (max-width: 425px) {
            height: 250px;
          }
        }
      }
      .screen-img {
        display: flex;
        gap: 10px;

        .screen-one {
          transform: rotate(-16deg);
        }
      }
      .contant-main {
        justify-content: space-between;
        padding-top: 24px;
        flex-wrap: wrap;
        gap: 10px;
        .game-content {
          padding-top: 20px;
          display: flex;
          align-items: center;
          gap: 20px;
          p {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      .banner-downlode-btn {
        margin-top: 30px;
        display: flex;
        gap: 20px;
        @media (max-width: 768px) {
          display: none;
        }
        .downlode-theme {
          margin: 10px 0;
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 500;
          font-family: "Josefin Sans", sans-serif !important;
          width: 100%;
          padding: 10px;
          justify-content: center;
          border-radius: 50px;
          background-color: #2d82e9;
          color: #fff;
          border: 0;
          &:hover {
            background-color: #ffbe45;
            color: #000;
          }
          svg {
            margin-top: -4px;
          }
        }
      }
    }
  }
  .searvice-part {
    padding: 0 16px;
    padding-top: 160px;
    @media (max-width: 768px) {
      padding-top: 100px;
    }
    .service-main {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      @media (max-width: 824px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 576px) {
        grid-template-columns: 1fr;
      }
      .h-part-one {
        gap: 20px;
      }
      .service-part {
        width: 100%;
        padding: 20px;
        transition: 0.3s;
        background-color: #132b49;
        flex-direction: column;
        display: flex;
        border-radius: 16px;
        &:hover {
          background-color: #1e3c61;
        }
        p {
          color: #fff;
          font-size: 20px;
          padding-top: 24px;
        }
        img {
          width: 60px;

          @media (max-width: 576px) {
            width: 50px;
          }
        }
        .payment-option {
          ul {
            padding-top: 20px;
            display: flex;
            gap: 10px;
          }
        }
      }
    }
  }
  .slider-main {
    padding: 96px 20px;

    @media (max-width: 576px) {
      padding: 40px 20px;
    }
    .slider-heading {
      h2 {
        font-size: 40px;
        color: #fff;

        @media (max-width: 768px) {
          font-size: 26px !important;
        }
      }
    }
    .slider-conteant {
      padding-top: 60px;

      @media (max-width: 768px) {
        display: none;
      }
      .slider-box-main {
        width: 100%;
        justify-content: space-between;
        gap: 20px;
        .slider-box-one {
          padding: 40px;
          background-color: #132b49;
          color: #fff;
          width: 100%;
          border: 1px solid hsla(0, 0%, 100%, 0.32);
          border-radius: 16px;
          .slide-counter {
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 4px;
            -webkit-text-stroke-color: hsla(0, 0%, 100%, 0.12);
            bottom: 0;
            font-size: 148px;
            font-weight: 700;
            left: 0;
            line-height: 156px;
            @media (max-width: 1024px) {
              font-size: 120px;
            }
            @media (max-width: 768px) {
              font-size: 80px;
            }
          }
          h2 {
            margin-bottom: 16px;
            color: #fff;
            @media (max-width: 1024px) {
              font-size: 30px;
            }
            @media (max-width: 824px) {
              font-size: 26px;
            }
          }
          p {
            font-size: 20px;
            color: #ffffff;
            padding-top: 16px;
            opacity: 0.5;
          }
          .slider-screen {
            display: flex;
            height: 100%;
            margin: auto;
            justify-content: center;
            img {
              width: 320px;
              height: 520px;
              border-bottom: 0 !important;
              border: 3px solid #fff;
              border-radius: 16px 16px 0 0 !important;

              @media (max-width: 992px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .overflow-main {
      display: none;
      @media (max-width: 768px) {
        display: block;
      }
      .overflow-slide {
        display: flex;
        margin-top: 40px;
        overflow-x: auto;
        gap: 20px;
        min-width: 1200px;
        .slider-box-main {
          width: 100%;
          justify-content: space-between;
          gap: 20px;
          .slider-box-one {
            padding: 10px;
            background-color: #132b49;
            color: #fff;
            width: 100%;
            border: 1px solid hsla(0, 0%, 100%, 0.32);
            border-radius: 16px;
            .slide-counter {
              -webkit-text-fill-color: transparent;
              -webkit-text-stroke-width: 4px;
              -webkit-text-stroke-color: hsla(0, 0%, 100%, 0.12);
              bottom: 0;
              font-size: 148px;
              font-weight: 700;
              left: 0;
              line-height: 90px;
              @media (max-width: 1024px) {
                font-size: 120px;
              }
              @media (max-width: 824px) {
                font-size: 80px;
              }
            }
            h2 {
              margin-bottom: 16px;
              color: #fff;
              @media (max-width: 1024px) {
                font-size: 30px;
              }
              @media (max-width: 824px) {
                font-size: 12px;
                line-height: normal;
              }
            }
            p {
              font-size: 20px;
              color: #ffffff;
              padding-top: 16px;
              opacity: 0.5;
              @media (max-width: 824px) {
                font-size: 12px;
              }
            }
            .slider-screen {
              display: flex;
              margin: auto;
              justify-content: center;
              img {
                border-bottom: 0 !important;
                border: 3px solid #fff;
                border-radius: 16px 16px 0 0 !important;

                @media (max-width: 992px) {
                  width: 100%;
                  height: 250px;
                }
              }
            }
          }
        }
      }
    }
  }
  .about-main {
    padding: 96px 20px;
    @media (max-width: 768px) {
      padding: 0px 20px;
    }
    .about-heading {
      h2 {
        color: #fff;
      }
    }
    .review-box-main {
      padding-top: 70px;
      @media (max-width: 768px) {
        padding-top: 40px;
      }
      .review-box {
        height: 100%;
        min-width: 1rem;
        background-color: #132b49;
        padding: 2rem;
        border-radius: 10px;
        word-wrap: break-word;
        position: relative;
        margin-inline: auto;
        .rating-main {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .rating-star {
            ul {
              display: flex;
              li {
                svg {
                  color: rgb(255, 208, 0);
                }
              }
            }
          }
        }
        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          gap: 100px;
          border-radius: 50%;
        }
        .customer-detalis {
          p {
            padding: 32px 0 18px;
            font-size: 20px;
            color: #fff;
            text-transform: uppercase;
          }
          span {
            font-size: 16px;
            color: #ffffff80;
          }
        }
        .review-content {
          p {
            padding-top: 18px;
            color: #ccc;
          }
        }
      }
    }
  }
  .safe-certified {
    padding: 96px 20px;
    @media (max-width: 768px) {
      padding-bottom: 40px;
    }
    .certified-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 60px;
      h2 {
        color: #fff;
      }
    }
    .certified-box-main {
      padding-top: 50px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      @media (max-width: 824px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 425px) {
        grid-template-columns: 1fr;
      }
      .certified-box-theme {
        @media (max-width: 992px) {
          margin-top: 30px;
        }
        p {
          padding-top: 20px;
          font-size: 18px;
          color: #fff;
        }
        @keyframes float {
          0% {
            box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
            transform: translatey(0px);
          }
          50% {
            box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
            transform: translatey(-20px);
          }
          100% {
            box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
            transform: translatey(0px);
          }
        }
      }
    }
  }
  .avatar {
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
  }
  .how-to-play {
    .bg-img {
      background-image: url(../../images/site-img/bg2.jpg);
      width: 100%;
      position: relative;
      height: 600px;
      padding: 60px 20px;
      overflow: hidden;
      background-position: center;
      background-repeat: no-repeat;
      object-fit: cover;
      @media (max-width: 576px) {
        height: auto;
      }
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #0f161c;
        opacity: 0.9;
      }
      .play-heading {
        position: relative;
        z-index: 500;
        h4 {
          color: #fff;
          font-size: 40px;
          font-weight: 700;
          line-height: 48px;
          @media (max-width: 576px) {
            font-size: 24px;
          }
        }
        p {
          padding-top: 36px;
          font-size: 18px;
          text-align: center;
          color: #fff;
          line-height: 36px;
        }
      }
      .how-item-main {
        padding-top: 120px;
        position: relative;
        z-index: 50;
        display: flex;
        color: #fff;
        @media (max-width: 576px) {
          flex-wrap: wrap;
          gap: 40px;
          padding-top: 60px;
        }
        .how-item {
          width: 100%;
          text-align: center;
          padding: 0 25px;
          position: relative;
          &:hover {
            .how-item__thumb {
              background-color: #42e5ff !important;
            }
          }
          &:hover {
            .how-item__content {
              h4 {
                color: #42e5ff;
              }
            }
          }
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            border: 1px dashed rgba(255, 200, 39, 0.7);
            top: 60px;
          }
          .how-item__thumb {
            width: 120px;
            height: 120px;
            background: #13181e;
            border-radius: 50%;
            font-size: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 30px;
            position: relative;
            box-shadow: -4.828px -4.828px 12px 0px rgba(10, 221, 255, 0.4),
              5px 4px 12px 0px rgb(19 92 110);
            svg {
              width: 60px;
              height: 60px;
            }
            .badge {
              position: absolute;
              right: -10px;
              border-radius: 50px;
              top: 10px;
              font-size: 18px;
              width: 30px;
              height: 30px;
              color: #1f1f23 !important;
              background: linear-gradient(0deg, #ff9c19 40%, #ffdd2d 110%);
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
            }
          }
          .how-item__content {
            h4 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.rummy-game {
  margin-top: 60px;
  padding: 20px;
  .rummy-heading {
    color: #fff;
    h2 {
      text-transform: lowercase;
      color: #fff;
    }
  }
  .rummy-part {
    padding-top: 96px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 20px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      padding-top: 30px;
    }
    .rummy-content {
      p {
        color: #83909f;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
      }
    }
    #app {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper {
      width: 265px;
      height: 432px;
      overflow: visible !important;
      @media (max-width: 499px) {
        width: 175px;
        height: 260px;
      }
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      width: 100% !important;
      height: 100%;
      background-size: cover;
      object-fit: cover;
      background-position: center;
    }
    .swiper-slide:nth-child(1) {
      background-image: url(../../assets/images/final-cards/C-1.png);
    }
    .swiper-slide:nth-child(2) {
      background-image: url(../../assets/images/final-cards/D-2.png);
    }
    .swiper-slide:nth-child(3) {
      background-image: url(../../assets/images/final-cards/H-3.png);
    }
    .swiper-slide:nth-child(4) {
      background-image: url(../../assets/images/final-cards/S-4.png);
    }
    .swiper-slide:nth-child(5) {
      background-image: url(../../assets/images/final-cards/C-5.png);
    }
    .swiper-slide:nth-child(6) {
      background-image: url(../../assets/images/final-cards/D-6.png);
    }
    .swiper-slide:nth-child(7) {
      background-image: url(../../assets/images/final-cards/H-7.png);
    }
    .swiper-slide:nth-child(8) {
      background-image: url(../../assets/images/final-cards/S-8.png);
    }
    .swiper-slide:nth-child(9) {
      background-image: url(../../assets/images/final-cards/C-9.png);
    }
    .swiper-slide:nth-child(10) {
      background-image: url(../../assets/images/final-cards/D-10.png);
    }
    .swiper-slide:nth-child(11) {
      background-image: url(../../assets/images/final-cards/S-11.png);
    }
    .swiper-slide:nth-child(12) {
      background-image: url(../../assets/images/final-cards/H-12.png);
    }
    .swiper-slide:nth-child(13) {
      background-image: url(../../assets/images/final-cards/S-13.png);
    }
  }
}
.Play-ludo {
  padding: 20px;
  .ludo-heading {
    padding-top: 96px;
    @media (max-width: 576px) {
      padding-top: 40px;
    }
    h2 {
      color: #fff;
    }
  }
  .ludo-main {
    display: flex;
    align-items: center;
    margin-top: 40px;
    gap: 70px;
    flex-direction: column;
    .ludo-content {
      p {
        color: #83909f;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
      }
    }
  }
  $red: #ea4330;
  $green: #34a853;
  $yellow: #fbbc05;
  $blue: #4285f4;
  $gate-color: #fff;
  $base-color: #ddd;
  $border: #aaa;
  $break-sm: 699px;
  $break-xm: 499px;
  @mixin box-shadow($settings) {
    -moz-box-shadow: $settings;
    -webkit-box-shadow: $settings;
    box-shadow: $settings;
  }
  @mixin simbolo($color) {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    text-align: center;
    color: $color;
    font-size: 30px;
    line-height: 30px;
    @media (max-width: $break-sm) {
      top: 2px;
      font-size: 22px;
      line-height: 22px;
    }
  }

  @mixin create-token-color($color) {
    background: darken($color, 20%);
    &::before {
      background: darken($color, 10%);
    }
    &:hover,
    &:hover::before {
      background: darken($color, 10%);
    }
  }
  @mixin create-token($size, $margin) {
    position: relative;
    display: inline-block;
    height: $size;
    width: $size;
    background: gray;
    border-radius: $size/2;
    margin: $margin;
    cursor: pointer;
    border: 2px solid transparent;
    &::before {
      content: "";
      position: absolute;
      bottom: 1px;
      z-index: 500;
      left: 1px;
      top: 1px;
      right: 1px;
      border-radius: $size;
    }
    &.active {
      border: 2px solid $border;
    }
    &.red {
      @include create-token-color($red);
    }
    &.green {
      @include create-token-color($green);
    }
    &.blue {
      @include create-token-color($blue);
    }
    &.yellow {
      @include create-token-color($yellow);
    }
  }
  @mixin gradient-circle($a, $b) {
    background-color: $a;
    background: -moz-radial-gradient(circle, $a 0%, $b 100%);
    background: -webkit-radial-gradient(circle, $a 0%, $b 100%);
    background: radial-gradient(circle, $a 0%, $b 100%);
  }
  .board {
    display: none;
    height: 600px;
    width: 600px;
    grid-template-columns: repeat(15, 1fr);
    grid-template-rows: repeat(15, 1fr);
    gap: 0px 0px;
    border-radius: 1rem;
    overflow: hidden;
    background: #fff;
    transform: perspective(1000px) rotateX(30deg);
    transition: all 0.4s ease 0s;
    @include box-shadow((0 20px 50px -6px rgba(0, 0, 0, 0.4)));
    &:hover,
    &.static {
      transform: perspective(2000px) rotateX(0deg);
      @include box-shadow((0 0 60px -6px rgba(0, 0, 0, 0.4)));
    }
    &.loaded {
      display: grid;
    }
    & * {
      position: relative;
    }
    .base {
      display: block;
      z-index: 2;
      .inner {
        background-color: #fff;
        position: absolute;
        top: 40px;
        left: 40px;
        right: 40px;
        bottom: 40px;
        border-radius: 0.8rem;
        flex-wrap: wrap;
        display: flex;
        align-items: space-between;
        justify-content: space-between;
        flex: 1;
        .token {
          @include create-token(36px, 20px);
        }
      }
      &.r {
        grid-area: 1 / 1 / 7 / 7;
        background: $red;
      }
      &.g {
        grid-area: 1 / 10 / 7 / 16;
        background: $green;
      }
      &.b {
        grid-area: 10 / 1 / 16 / 7;
        background: $blue;
      }
      &.y {
        grid-area: 10 / 10 / 16 / 16;
        background: $yellow;
      }
    }
    .race-1 {
      grid-area: 7 / 2 / span 1 / span 1;
    }
    .race-2 {
      grid-area: 7 / 3 / span 1 / span 1;
    }
    .race-3 {
      grid-area: 7 / 4 / span 1 / span 1;
    }
    .race-4 {
      grid-area: 7 / 5 / span 1 / span 1;
    }
    .race-5 {
      grid-area: 7 / 6 / span 1 / span 1;
    }
    .race-6 {
      grid-area: 6 / 7 / span 1 / span 1;
    }
    .race-7 {
      grid-area: 5 / 7 / span 1 / span 1;
    }
    .race-8 {
      grid-area: 4 / 7 / span 1 / span 1;
    }
    .race-9 {
      grid-area: 3 / 7 / span 1 / span 1;
    }
    .race-10 {
      grid-area: 2 / 7 / span 1 / span 1;
    }
    .race-11 {
      grid-area: 1 / 7 / span 1 / span 1;
    }
    .race-12 {
      grid-area: 1 / 8 / span 1 / span 1;
    }
    .race-13 {
      grid-area: 1 / 9 / span 1 / span 1;
    }
    .race-10 {
      grid-area: 2 / 7 / span 1 / span 1;
    }
    .race-14 {
      grid-area: 2 / 9 / span 1 / span 1;
    }
    .race-15 {
      grid-area: 3 / 9 / span 1 / span 1;
    }
    .race-16 {
      grid-area: 4 / 9 / span 1 / span 1;
    }
    .race-17 {
      grid-area: 5 / 9 / span 1 / span 1;
    }
    .race-18 {
      grid-area: 6 / 9 / span 1 / span 1;
    }
    .race-19 {
      grid-area: 7 / 10 / span 1 / span 1;
    }
    .race-20 {
      grid-area: 7 / 11 / span 1 / span 1;
    }
    .race-21 {
      grid-area: 7 / 12 / span 1 / span 1;
    }
    .race-22 {
      grid-area: 7 / 13 / span 1 / span 1;
    }
    .race-23 {
      grid-area: 7 / 14 / span 1 / span 1;
    }
    .race-24 {
      grid-area: 7 / 15 / span 1 / span 1;
    }
    .race-25 {
      grid-area: 8 / 15 / span 1 / span 1;
    }
    .race-26 {
      grid-area: 9 / 15 / span 1 / span 1;
    }
    .race-27 {
      grid-area: 9 / 14 / span 1 / span 1;
    }
    .race-28 {
      grid-area: 9 / 13 / span 1 / span 1;
    }
    .race-29 {
      grid-area: 9 / 12 / span 1 / span 1;
    }
    .race-30 {
      grid-area: 9 / 11 / span 1 / span 1;
    }
    .race-31 {
      grid-area: 9 / 10 / span 1 / span 1;
    }
    .race-32 {
      grid-area: 10 / 9 / span 1 / span 1;
    }
    .race-33 {
      grid-area: 11 / 9 / span 1 / span 1;
    }
    .race-34 {
      grid-area: 12 / 9 / span 1 / span 1;
    }
    .race-35 {
      grid-area: 13 / 9 / span 1 / span 1;
    }
    .race-36 {
      grid-area: 14 / 9 / span 1 / span 1;
    }
    .race-37 {
      grid-area: 15 / 9 / span 1 / span 1;
    }
    .race-38 {
      grid-area: 15 / 8 / span 1 / span 1;
    }
    .race-39 {
      grid-area: 15 / 7 / span 1 / span 1;
    }
    .race-40 {
      grid-area: 14 / 7 / span 1 / span 1;
    }
    .race-41 {
      grid-area: 13 / 7 / span 1 / span 1;
    }
    .race-42 {
      grid-area: 12 / 7 / span 1 / span 1;
    }
    .race-43 {
      grid-area: 11 / 7 / span 1 / span 1;
    }
    .race-44 {
      grid-area: 10 / 7 / span 1 / span 1;
    }
    .race-45 {
      grid-area: 9 / 6 / span 1 / span 1;
    }
    .race-46 {
      grid-area: 9 / 5 / span 1 / span 1;
    }
    .race-47 {
      grid-area: 9 / 4 / span 1 / span 1;
    }
    .race-48 {
      grid-area: 9 / 3 / span 1 / span 1;
    }
    .race-49 {
      grid-area: 9 / 2 / span 1 / span 1;
    }
    .race-50 {
      grid-area: 9 / 1 / span 1 / span 1;
    }
    .race-51 {
      grid-area: 8 / 1 / span 1 / span 1;
    }
    .race-52 {
      grid-area: 7 / 1 / span 1 / span 1;
    }
    .home {
      grid-area: 7 / 7 / 10 / 10;
      border: 60px solid $border;
      border-left-color: $red;
      border-top-color: $green;
      border-right-color: $yellow;
      border-bottom-color: $blue;
      z-index: 1;
    }
    .r.final,
    .y.final {
      grid-area: 8 / 2 / 9 / 8;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      background-color: $red;
    }
    .y.final {
      grid-area: 8 / 9 / 9 / 15;
      background-color: $yellow;
    }
    .b.final,
    .g.final {
      grid-area: 9 / 8 / 15 / 9;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      background-color: $blue;
    }
    .g.final {
      grid-area: 2 / 8 / 8 / 9;
      background-color: $green;
    }
    .cell {
      border: 1px solid $border;
      z-index: 2;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      .inner {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        .token {
          @include create-token(13px, 1px);
          z-index: 10;
        }
      }
      &.transparent {
        border: 1px solid transparent;
        background: transparent;
      }
      &.safe-zone {
        &::before {
          content: "★";
          @include simbolo((darken($base-color, 10%)));
        }
      }
      &.start-r {
        background: $red;
        &::before {
          color: lighten($red, 10%);
        }
      }
      &.start-b {
        background: $blue;
        &::before {
          color: lighten($blue, 10%);
        }
      }
      &.start-g {
        background: $green;

        &::before {
          color: lighten($green, 10%);
        }
      }
      &.start-y {
        background: $yellow;

        &::before {
          color: lighten($yellow, 10%);
        }
      }
      &.end-r {
        &::before {
          content: "\1F846";
          @include simbolo($red);
        }
      }
      &.end-b {
        &::before {
          content: "\1F845";
          @include simbolo($blue);
        }
      }
      &.end-g {
        &::before {
          content: "\1F847";
          @include simbolo($green);
        }
      }
      &.end-y {
        &::before {
          content: "\1F844";
          @include simbolo($yellow);
        }
      }
    }
  }
  $particles: 80;
  $width: 600;
  $height: 600;
  $box-shadow: ();
  $box-shadow2: ();
  @for $i from 0 through $particles {
    $box-shadow: $box-shadow,
      random($width)-$width /
        2 +
        px
        random($height)-$height /
        1.2 +
        px
        hsl(random(360), 100, 50);
    $box-shadow2: $box-shadow2, 0 0 #fff600;
  }
  @mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
      @content;
    }
    @-moz-keyframes #{$animationName} {
      @content;
    }
    @-o-keyframes #{$animationName} {
      @content;
    }
    @-ms-keyframes #{$animationName} {
      @content;
    }
    @keyframes #{$animationName} {
      @content;
    }
  }
  @mixin animation-delay($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
  }
  @mixin animation-duration($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
  }
  @mixin animation($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
  }
  @mixin transform($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
  }
  .sparks::before,
  .sparks::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: $box-shadow2;
    @include animation(
      (
        1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards
      )
    );
  }
  .sparks::after {
    @include animation-delay((1.25s, 1.25s, 1.25s));
    @include animation-duration((1.25s, 1.25s, 6.25s));
  }
  @include keyframes(bang) {
    to {
      box-shadow: $box-shadow;
    }
  }
  @include keyframes(gravity) {
    to {
      @include transform(translateY(200px));
      opacity: 0;
    }
  }
  @include keyframes(position) {
    0%,
    19.9% {
      margin-top: 50%;
      margin-left: 40%;
    }
    20%,
    39.9% {
      margin-top: 60%;
      margin-left: 30%;
    }
    40%,
    59.9% {
      margin-top: 30%;
      margin-left: 70%;
    }
    60%,
    79.9% {
      margin-top: 50%;
      margin-left: 20%;
    }
    80%,
    99.9% {
      margin-top: 80%;
      margin-left: 80%;
    }
  }
  .dice {
    position: fixed;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    flex-wrap: wrap;
    align-content: center;
    background: linear-gradient(top, #222, #333);
    cursor: pointer;
    &.dice-r,
    &.dice-g {
      top: 50px;
    }
    &.dice-r,
    &.dice-b {
      left: 50px;
    }
    &.dice-g,
    &.dice-y {
      right: 50px;
    }
    &.dice-b,
    &.dice-y {
      bottom: 50px;
    }
    .face-1,
    .face-2,
    .face-3,
    .face-4,
    .face-5,
    .face-6 {
      padding: 5px;
      background-color: #fff;
      width: 60px;
      height: 60px;
      object-fit: contain;
      border-radius: 10%;
      display: none;
      @include box-shadow((0 0 40px -10px rgba(0, 0, 0, 0.4)));
      &.show,
      .col {
        display: flex;
      }
      .pip {
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        background-color: #333;
      }
    }
    &.dice-r .pip {
      background-color: $red;
    }
    &.dice-g .pip {
      background-color: $green;
    }
    &.dice-y .pip {
      background-color: $yellow;
    }
    &.dice-b .pip {
      background-color: $blue;
    }
    .face-1 {
      justify-content: center;
      align-items: center;
    }
    .face-2 {
      justify-content: space-between;
    }
    .face-2 .pip:nth-of-type(2) {
      align-self: flex-end;
    }
    .face-3 {
      justify-content: space-between;
    }
    .face-3 .pip:nth-of-type(2) {
      align-self: center;
    }
    .face-3 .pip:nth-of-type(3) {
      align-self: flex-end;
    }
    .face-4,
    .face-6 {
      justify-content: space-between;
    }
    .face-4 .col,
    .face-6 .col {
      flex-direction: column;
      justify-content: space-between;
    }
    .face-5 {
      justify-content: space-between;
    }
    .face-5 .col {
      flex-direction: column;
      justify-content: space-between;
    }
    .face-5 .col:nth-of-type(2) {
      justify-content: center;
    }
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #111;
    border-radius: 4px;
    &:active {
      background-color: #333;
    }
    &:hover {
      background-color: #222;
    }
  }
  ::-webkit-scrollbar-track {
    background: white;
    &:hover,
    &:active {
      background: white;
    }
  }
  @media (max-width: $break-sm) {
    .board {
      width: 400px;
      height: 400px;
      .base .inner {
        top: 25px;
        left: 25px;
        right: 25px;
        bottom: 25px;
        .token {
          @include create-token(30px, 12px);
        }
      }
      .cell .inner .token {
        @include create-token(10px, 1px);
      }
      .home {
        border-width: 40px;
      }
    }
  }
  @media (max-width: $break-xm) {
    .board {
      width: 300px;
      height: 300px;
      .base .inner {
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        .token {
          @include create-token(24px, 7px);
        }
      }
      .cell .inner .token {
        @include create-token(6px, 1px);
      }
      .home {
        border-width: 20px;
      }
    }
  }
}
.games-content {
  padding: 20px;
  .screen-img {
    h2 {
      color: #fff;
    }
    p {
      color: #83909f;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 16px;
      padding: 40px 0;
    }
    img {
      width: 100%;
    }
  }
  .paragrapg-txt {
    h4 {
      color: #fff;
      text-align: start;
      text-transform: lowercase;
      padding: 10px 0;
    }
    h5 {
      color: #fff;
      padding: 10px 0;
      font-size: 24px;
    }
    p {
      color: #83909f;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 16px;
      padding: 10px 0;
    }
    .demo-card {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
  }
}
.questions-main {
  padding: 0 20px;
  .questions-heading {
    padding-top: 65px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    text-align: start !important;
    justify-content: space-between;
    @media (max-width: 768px) {
      h4 {
        font-size: 16px !important;
        line-height: 24px;
        text-align: start;
      }
    }
    button {
      width: 120px;
      height: 40px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 600;
      padding: 5px 20px;
      border: 0;
      background-color: #2d82e9;
      color: #fff;
    }
  }
  .questions-space {
    padding-top: 30px;
    .questions {
      color: #fff;
      padding: 28px 0;
      border-bottom: 1px solid hsl(0deg 0% 100% / 33%);
      h5 {
        font-size: 24px !important;
        color: #fff;
        font-weight: 400;
        font-family: "Josefin Sans", sans-serif !important;
        @media (max-width: 576px) {
          font-size: 16px !important;
        }
      }
      p {
        padding-top: 20px;
        color: #ffffff80;
      }
    }
  }
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 14px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: rgb(255, 255, 255) !important;
  -webkit-font-smoothing: antialiased;
}
.slick-dots {
  background-color: #132b49 !important;
  width: max-content !important;
  left: 50%;
  padding: 5px 10px !important;
  transform: translate(-50%, 30px);
  border-radius: 50px;
}
.slick-list {
  margin: 0 -10px;
}
.slick-slide > div {
  padding: 0 10px;
}
.landing-main.stickyhead {
  position: fixed;
  left: 50%;
  top: -10px;
  background-color: #000;
  transform: translate(-50%, 10px);
  z-index: 50000;
  width: 100%;
}
// Footer scss
.footer-main {
  margin-top: 96px;
  padding: 0 20px;
  background-color: #132b49;
  padding-top: 72px;
  background-image: url(../../images/site-img/bg2.jpg);
  width: 100%;
  padding: 60px 0px;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  position: relative;
  @media (max-width: 768px) {
    padding-bottom: 185px;
  }
  &::after {
    background-color: #000;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.9;
  }
  .f-part-main {
    position: relative;
    z-index: 500;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    gap: 30px;
    border-bottom: 1px solid #4c4c4c;
    padding-bottom: 25px;

    @media (max-width: 576px) {
      justify-content: center;
      flex-wrap: wrap;
    }
    .footer-part-two {
      display: flex;
      align-items: start;
      gap: 20px;
      justify-content: end;
      width: 100%;
      @media (max-width: 576px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      .footer-list {
        ul {
          padding-top: 10px;
          display: flex;
          gap: 30px;
          flex-wrap: wrap;
          justify-content: center;
          li {
            padding: 10px 0;
            a {
              transition: 0.3s;
              color: #fff;
              font-size: 18px;
              &:hover {
                color: #fbbc05;
              }
            }
          }
        }
      }
    }
  }
  .f-end-txt {
    position: relative;
    z-index: 500;
    color: #fff;
    padding-top: 60px !important;
    width: 100%;
    padding: 12px;
    text-align: center;
  }
}
.landing-main nav {
  background-color: transparent;
}
.stickyhead {
  background-color: #000 !important;
}
.landing-main.stickyhead nav {
  background-color: #000 !important;
}
.back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
  @media (max-width: 768px) {
    bottom: 185px;
  }
}
.back-to-top-button {
  background-color: #fbbc05;
  color: #000;
  border: none;
  padding: 10px 10px;
  border-radius: 50px;
  width: 44px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
  height: 44px;
}
.downlode-btns {
  display: none;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 8000;
  background-color: #000;
  padding: 20px;
  @media (max-width: 768px) {
    display: block;
  }
}
.btn-theme {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  justify-content: center;
  border-radius: 50px;
  background-color: #2d82e9;
  color: #fff;
  border: 0;
  @media (max-width: 576px) {
    padding: 6px;
  }
}
.how-item-none.how-item::before {
  opacity: 0;
  @media (max-width: 576px) {
    opacity: 1;
  }
}
// Privacy Policys css
.privacypolicys-main {
  .bg-img {
    background-image: url(../../images/site-img/Privacy-bg.jpg);
    width: 100%;
    height: 400px;
    background-size: cover !important;
    position: relative;
    background-position: top top;
    background-repeat: no-repeat !important;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #00394e;
      opacity: 0.9;
    }
    .bg-text {
      width: 100%;
      text-align: center;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 50;
      gap: 20px;
      h2 {
        color: #fff;
      }
      .pagination {
        display: flex;
        padding-top: 36px;
        justify-content: center;
        gap: 20px;
        align-items: center;
        p {
          font-size: 20px;
        }
      }
    }
  }
  .policy-txt {
    padding: 60px 0px 0px 16px;
    h2 {
      color: #ffffffcc;
      text-align: center;
    }
    p {
      color: #fff;
      margin-bottom: 25px;
      span {
        font-size: 24px;
      }
    }
    ul {
      margin-bottom: 75px;
      color: #ffffffcc;
      li {
        font-size: 18px;
        padding-bottom: 15px;
      }
    }
  }
}
.pulsating-circle {
  position: absolute;
  left: 7px;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #ffd400;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

// Refer css
.refer-main {
  background: #00394e;
  .bg-img {
    background-image: linear-gradient(
        0deg,
        rgba(21, 18, 37, 0.99),
        rgba(30, 19, 53, 0.95),
        rgba(54, 25, 85, 0.85)
      ),
      url(../../images/site-img/Privacy-bg.jpg);
    width: 100%;
    height: 100vh;
    text-align: center;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo img {
      width: 150px;
      @media (max-width: 400px) or (max-height: 600px) {
        width: 125px;
      }
    }
    .bg-text {
      width: 100%;
      text-align: center;
      color: #fff;
      left: 50%;
      top: 50%;
      h2 {
        color: #fff;
      }
      .pagination {
        display: flex;
        padding: 36px 0 80px;
        @media (max-width: 400px) or (max-height: 600px) {
          padding: 30px 0 40px;
        }
        justify-content: center;
        align-items: center;
        p {
          font-size: 24px;
          @media (max-width: 400px) or (max-height: 600px) {
            font-size: 20px;
          }
        }
      }
    }
    .refer-form {
      margin: auto;
      width: 350px;
      @media (max-width: 400px) or (max-height: 600px) {
        width: 300px;
      }
      text-align: left;
      .mobile-number {
        margin-bottom: 15px;
        @media (max-width: 400px) or (max-height: 600px) {
          margin-bottom: 10px;
        }
      }
      label {
        color: #fff;
        font-size: 16px;
        @media (max-width: 400px) or (max-height: 600px) {
          font-size: 12px;
        }
      }
      input {
        border: 2px solid rgb(109 13 164);
        background: transparent;
        outline: none;
      }
      .input-text {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        margin-top: 5px;
        border-radius: 15px;
        font-size: 1.2rem;
        line-height: 2.4rem;
        padding: 0.3rem 1.2rem;
        color: #fff;
        background: linear-gradient(
          45deg,
          rgba(62, 11, 106, 0.99),
          rgba(112, 10, 138, 0.85)
        );
        @media (max-width: 400px) or (max-height: 600px) {
          font-size: 1rem;
          padding: 0.05rem 1.2rem;
          margin-top: 2px;
        }
      }
      .otp-content {
        margin-top: 15px;
        @media (max-width: 400px) or (max-height: 600px) {
          margin-top: 10px;
        }
        input {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 15% !important;
          max-width: 400px;
          margin: 0 auto;
          margin-top: 5px;
          border-radius: 15px;
          font-size: 1.2rem;
          line-height: 2.4rem;
          padding: 0.3rem 0.2rem;
          color: #fff;
          background: linear-gradient(
            45deg,
            rgba(62, 11, 106, 0.99),
            rgba(112, 10, 138, 0.85)
          );
          @media (max-width: 400px) or (max-height: 600px) {
            font-size: 1rem;
            margin-top: 2px;
            padding: 0.05rem 0.2rem;
          }
        }
        input:first-child {
          margin-left: 0;
        }
        input:last-child {
          margin-right: 0;
        }
        .countdown-text {
          margin-top: 10px;
          @media (max-width: 400px) or (max-height: 600px) {
            margin-top: 5px;
          }
          text-align: right;
          color: #ddd;
          .resend-otp {
            display: flex;
            justify-content: end;
            button {
              background: none;
              border: none;
              color: #0060b6;
              font-weight: 700;
              margin-left: 8px;
            }
          }
        }
      }
      .submit-button {
        margin-top: 45px;
        margin-bottom: 100px;
        @media (max-width: 400px) or (max-height: 600px) {
          margin-top: 25px;
          margin-bottom: 50px;
        }
        button {
          width: 100%;
          border: none;
          border-radius: 15px;
          padding: 1rem 0;
          color: #fff;
          font-size: 18px;
          background: linear-gradient(
            0deg,
            rgba(62, 11, 106, 0.99),
            rgba(214, 48, 177)
          );
        }
      }
    }
    .copyright {
      position: absolute;
      bottom: 35px;
      color: #ddd;
      @media (max-width: 400px) or (max-height: 600px) {
        bottom: 25px;
      }
    }
  }
}

.refer-sweetalert {
  .swal2-popup {
    border: 2px solid rgb(157 48 132);
    border-radius: 22px;
    width: 400px;
    background: linear-gradient(45deg, rgb(73, 14, 130), rgb(104, 9, 120));
  }
  .swal2-confirm,
  .swal2-confirm:hover {
    background: linear-gradient(
      0deg,
      rgb(144, 00, 255),
      rgb(214, 48, 177)
    ) !important;
    color: white !important;
    border: none !important;
    border-radius: 15px;
    width: 100px;
  }

  .swal2-title,
  .swal2-html-container {
    color: #ddd !important;
  }
  .swal2-html-container {
    padding-top: 25px;
  }
}
