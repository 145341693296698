* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  letter-spacing: normal;
}
body{
  font-family: "Inter", sans-serif ;
  font-weight: 400;
  font-size: 14px;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .left_side_wrapper {
    width: 80% !important;
  }
}

@import "~react-datepicker/dist/react-datepicker.css";
// @import "assets/css/app";
@import "./assets/Style/app.scss";

tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root, .table_loader_details{
  position: relative;
}

.table_loader_details{
  height: 91vh;
  overflow: hidden;
}

.Testing{
  position: absolute;
  /* position: fixed; */
  display: block;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255);
  // z-index: 99;
  text-align: center;

}
.right_side_layout_details{
  position: relative;
}