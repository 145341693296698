.dashboard_wrapper {
  display: flex;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 991px) {
    .left_side_wrapper {
      transform: translateX(-100%);
      position: fixed;
      transition: 0.3s;
    }
    &.active {
      .left_side_wrapper {
        transform: translateX(0);
      }
    }
  }
  .left_side_wrapper {
    width: 100%;
    max-width: 280px;
    height: 100%;
    overflow: hidden;
    z-index: 999;
    height: 100%;
    bottom: 0;
    top: 0;
    background: $light_black;
    color: $white;
    .logout_btn {
      border-top: 1px solid !important;
      svg {
        color: #97a6ba;
        width: 20px;
        height: 20px;
        margin-right: 20px;
        fill: #97a6ba;
      }
    }
    .gp_left-bar {
      position: relative;
      height: 100%;
      padding-top: 10px;
    }
    .left_side_inner_sec {
      .tab_sidebar_details {
        height: calc(100vh - 130px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 12px;
        padding-top: 16px;
      }
      .tab_sidebar_details::-webkit-scrollbar {
        height: 0.2rem;
        width: 0.4rem;
      } /* width */
      .tab_sidebar_details::-webkit-scrollbar-track {
        background: #bfc6cf;
      } /* Track */
      .tab_sidebar_details::-webkit-scrollbar-thumb {
        background: #3e4a58;
      } /* Handle */
      .tab_sidebar_details::-webkit-scrollbar-thumb:hover {
        background: #495568;
      } /* Handle on hover */
    }
    .icon-left-side,
    .Game_Icon {
      svg {
        color: #97a6ba;
        width: 20px;
        height: 20px;
        margin-right: 20px;
        fill: #97a6ba;
      }
    }
    .Game_Icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: #cfd8e3;
      font-family: "Inter", sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .list_item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 18px;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      text-decoration: none;
      transition: background-color 375ms cubic-bezier(0.25, 0.8, 0.25, 1);
      color: #cfd8e3;
      border-radius: 4px;
    }
  }
  .right_side_wrapper {
    width: 100%;
    max-width: calc(100% - 280px);
    background: $login_Bg;
    position: relative;
    overflow: auto;
    @media screen and (max-width: 991px) {
      max-width: 100% !important;
    }
  }
}

//------------------------------------- dashboard Page-------------------------------------------------------------
.dashboard-section {
  .Logo {
    //width: 730px;
    height: 250px;
    object-fit: contain;
    margin: 0 auto;
  }
  h6 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    padding-top: 35px;
    margin-top: 0;
    font-family: "Inter", sans-serif;
  }
}

.side_logo img {
  height: 62px;
  margin: 12px;
  object-fit: cover;
  width: auto;
}

.side_logo span {
  font-size: 22px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.side_logo_box {
  background: linear-gradient(90deg, #0ec9f4, #0023bc);
  // background: linear-gradient(
  //   90deg,
  //   #0ec9f4,
  //   #005fd0,
  //   #0023bc,
  //   #005fd0,
  //   #0ec9f4
  // );
  margin-top: -10px;
  .side_logo {
    height: 86px;
  }
}

.css-1ujnqem-MuiTabs-root .MuiTabs-scrollButtons {
  display: -webkit-inline-box !important;
}

@media screen and (min-width: 320px) and (max-width: 820px) {
  .tab .tab_bg_white {
    background: #ffffff;
    padding: 0;
  }
}
