.loader{
  position: fixed;
  z-index: 999;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #1d1f2b;
  background: #1E1E1E;
}

.loader_css{
  .btn-loader{
    height: 24px;
    width: 24px;
    margin: 0 auto;
    span{
      width: 100% !important;
      height: 100% !important;
      svg{
        color: white;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}