.change-password{
  a{
    color: #0277BD;
    font-weight: bold !important;
    text-decoration: none;
    &:hover{
      border-bottom-color: #0277BD;
      text-decoration: initial;
    }
  }
}

.forgot_password_modal{
  .title{
    h3{
      font-weight: 600 !important;
      font-size: 16px !important;
      text-align: center;
      margin-bottom: 11px;
    }
  }
  .forgot_password_content{
    p{
      font-size: 13px !important;
    }
  }
  .forgot_email_form{
    margin-top: 20px;
    form{
      .formData{
        label{
          font-weight: 600 !important;
        }
        .emailWrap{
          margin-bottom: 25px;
          input{
            border: 1px solid lightgrey;
            padding: 10px;
            width: 100%;
            border-radius: 5px;
            margin-top: 5px;
          }
        }
      }
      button{
        width: 100%;
        background: #2977bd;
        border: none;
        padding: 10px;
        border-radius: 5px;
        color: white;
        cursor: pointer;
      }
    }
  }
}

.change-password-section{
  .formData{
    max-width: 320px;
    label {
      color: #27303f !important;
      font-weight: 500 !important;
      font-size: 14px !important;
    }
    .sd_password_eye_open {
      cursor: pointer;
    }
    .passWrap {
      margin: 8px 0 5px;
      display: block;
      width: 100%;
      border: 1px solid #cfd8e3;
      outline: none;
      font-style: normal;
      font-size: 12px;
      line-height: 14px;
      color: #000;
      background: white;
      border-radius: 5px;
      display: flex;
      align-items: center;
      .passInput {
        width: 100%;
        display: flex;
        input {
          width: 100%;
          border: none;
          border-radius: 0;
          margin: 0;
          font-style: normal;
          font-size: 12px;
          line-height: 14px;
          color: #000;
          background: transparent;
          outline-style: none;
          padding: 12px 10px;
        }
        .sd_passoword_toggle {
          border: none;
          width: 20px;
          margin-right: 15px;
          background: transparent !important;
          min-width: auto !important;
          padding: 0 !important;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
  .btn-change-password{
    padding-top: 20px;
    button{
      max-width: 156px;
      width: 100%;
      min-height: 38px;
      height: 100%;
      background-color: #0277BD;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
      color: #f0f5ff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.forgot-password-btn{
  .btn-loader{
    svg,span{
      width: 20px !important;
      height: 20px !important;
      color: white !important;
    }
  }
}
.reset-password-section{
  padding: 32px 40px;
  border: 1px solid #0c0d13;
  border-radius: 10px;
}
.change-password-details{
  input{
    height: 48px !important;
  }
}