.invoice-box {
    max-width: 50rem;
    margin: auto;
    padding: 30px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
    text-align: right;
}

.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}

/** RTL **/
.invoice-box.rtl {
    direction: rtl;
    font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
        sans-serif;
}

.invoice-box.rtl table {
    text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
    text-align: left;
}

.terms_condi .terms p {
    margin-top: 10rem;
    margin-bottom: 0;
}

.Acknowledgement {
    color: #0B7CFC;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 2rem;
}

.compony_info{
    margin-bottom: 1rem;
}

.heading{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid #dfdfdf;
    font-weight: 700;
    background: #dfdfdf;
}
.item{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid #dfdfdf;

}
.top{
    display: flex;
    justify-content: space-between;

}
.information{
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;

}
.ViewInvoicePopup{
    width: 50rem !important;
}
