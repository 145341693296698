.level_popup_form_field_left{
  .dropdown_content_list_field {
    display: flex;
    align-items: center;
    margin-top: 15px;
    input[type='checkbox'] {
      width: 15px;
      height: 15px;
      cursor: pointer;
      min-height: 15px;
      margin: 0;
      margin-right:10px;
    }
  }
}

.bank_upi_section{
  .profile .user_profile_pic img{
    border-radius: 0;
  }
}

.withdrawal_processing_section{
  .filter_days_details_dropDown.gst-dropdown_width{
    width: 100%;
  }
}

.transaction_id_section{
  .user_profile .user_profile_pic img{
    height: 200px !important;
  }
}

.withdrawal_details_view{
  .headToHead_popup_details{
    border: none;
  }
}
.outer-box.withdrawal_details_table{
  margin: 32px 32px;
  padding: 30px 20px;
  span.edit_btn.orderId_details{
    font-size: 12px ;
  }
.withdrawal_left_section{
  width: 309px;
  p{
    margin-bottom: 7px;
  }
}
  table .table_cell_thead{
    min-width: 53px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .css-a72elr{
    width: 300px !important;
  }
}
@media screen and (min-width: 425px) and (max-width: 768px) {}
@media screen and (min-width: 769px) and (max-width: 1024px) {}
