$dark_blue:#1d1f2b;
$white:#fff;
$black:#000;
$light_blue:#1976d2;
$dark_red:#c81e1e;
$red:#ff0000;
$dark_sky:#64748b;
$light_sky:#97a6ba;


$blue_dot:#1784E9;

$login_Bg: #f1f5f9;
$login_Bg: #f1f5f9;
$deep_black: #1A1A1A;
$light_black:#1E1E1E;
$black: #000000;
$white: #ffffff;
$purple: #A8ABE0;
$text_blue:#3079EF;
$blue_dot:#1784E9;
$dark_blue: #457B9D;
$maroon: #C00C2D;
$red: #DE2B2B;
$light_red:#F84747;
$gray: #868686;
$gray_think: #F8F8F8;
$gray_think_border: #DEDEDE;
$light_gray: #E8EAF0;
$silver_gray: #495568;
$light_silver: #BFC6CF;
$dark_silver:#3E4A58;
$gray_label: #595959;
$gray_place: #C4C4C4;
$gray_solid:#BCBCBC;
$text_gray: #999999;
$text_think: #636363;
$dark_gray: #5D737E;

$screen-1199: 1199px;
$screen-1075: 1075px;
$screen-960: 960px;
$screen-768: 768px;
$screen-700: 700px;
$screen-665: 665px;
$screen-600: 600px;
$screen-576: 576px;
$screen-550: 550px;
$screen-500: 500px;
$screen-480: 480px;
$screen-450: 450px;
$screen-395: 395px;
$screen-375: 375px;
$screen-350: 350px;