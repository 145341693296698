.bot_config{
  //margin: 32px 40px;
  .main_setting_block .switch_box .switch input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  .main_setting_block {
    width: calc(100% - 25rem);
    position: relative;
    margin: 20px 0;
    .main_switch_block{
      display: flex;
      justify-content: space-between;
    }
    h4 {
      margin: 0;
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
      color: #25282C;
    }
    h5{
      font-family: "Inter", sans-serif;
      font-weight: 600;
      font-size: 14px;
      width: 100%;
    }
    button.save_btn {
      max-width: 84px;
      width: 100%;
      background: #C00C2D;
      border-radius: 20px;
      padding: 10px;
      border: none;
      font-family: montserrat_bold;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      border: 1px solid #C00C2D;
      letter-spacing: 0.3px;
      cursor: pointer;
      &:hover {
        background-color: transparent;
        color: #C00C2D;
      }
    }

    .switch_box {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: calc(100% - 84px);

      .switch input {
        opacity: 0;
        position: absolute;
        z-index: 9;
        width: 100%;
        height: 20px;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        cursor: pointer;
      }

      .switch {
        display: inline-block;
        max-width: 42px;
        width: 100%;
        position: relative;
      }

      .slider {
        max-width: 42px;
        width: 100%;
        height: 20px;
        position: absolute;
        border-radius: 20px;
        background: #457B9D;
        cursor: pointer;
        border: 4px solid transparent;
        overflow: visible;
        transition: 0.4s;
        z-index: 1;
      }

      .slider:before {
        position: absolute;
        content: "";
        width: 26px;
        height: 26px;
        background: #FFFFFF;
        border-radius: 100px;
        top: -8px;
        left: -6px;
        -webkit-transform: translateX(-0px);
        transform: translateX(-0px);
        -webkit-transition: .4s;
        transition: .4s;
        -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      }

      .switch input:checked+.slider:before {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
      }

      .off,
      .on {
        color: #959595;
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.6px;
        text-transform: capitalize;
      }

      .off {
        padding-right: 12px;
      }

      .on {
        padding-left: 12px;
      }

      .off.checked,
      .on.checked {
        color: black;
      }

      input[type="checkbox"]:disabled~span.slider {
        background: rgb(69 124 158 / 50%);
      }
    }

  }
  .game-play-rules{
    text-align: left;
    button{
      width: 20%;
    }
  }
};